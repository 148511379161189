import { React, useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';
import clsx from 'clsx';

import { Brightness4 } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import InventoryIcon from '@mui/icons-material/Inventory';
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey';
import MenuIcon from '@mui/icons-material/Menu';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import SchoolIcon from '@mui/icons-material/School';
import StoreIcon from '@mui/icons-material/Store';
import { AppBar, Container, Drawer, Hidden, ListItemButton, ListItemIcon, ListItemText, Tooltip, useMediaQuery } from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Profile from '../appBar/profile';
import MenuSistema from '../home/menu-sistemas';
import AuthService from '../service';


const PREFIX = 'Layout'
const DRAWER_WIDTH = 240

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarOpen: `${PREFIX}-toolbar-open`,
  toolbarIcon: `${PREFIX}-toolbarIcon`,
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButton: `${PREFIX}-menuButton`,
  menuButtonHidden: `${PREFIX}-menuButtonHidden`,
  drawerPaper: `${PREFIX}-drawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  appBarSpacer: `${PREFIX}-appBarSpacer`,
  content: `${PREFIX}-content`,
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  fixedHeight: `${PREFIX}-fixedHeight`
}

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
      display: 'flex',
    },
  
    [`& .${classes.toolbar}`]: {
      paddingRight: 24,
    },
  
    [`& .${classes.toolbarOpen}`]: {
      paddingLeft: 0,
    },
  
    [`& .${classes.toolbarIcon}`]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
  
    [`& .${classes.appBar}`]: {
      zIndex: theme.zIndex.appBar,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
  
    [`& .${classes.appBarShift}`]: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
  
      [theme.breakpoints.up('sm')]: {
        marginLeft: DRAWER_WIDTH,
        width: `calc(100% - ${DRAWER_WIDTH}px)`,
      },
    },
  
    [`& .${classes.menuButton}`]: {
      //marginRight: 26,
    },
  
    [`& .${classes.menuButtonHidden}`]: {
      display: 'none',
    },
  
    [`& .${classes.drawerPaper}`]: {
      zIndex: 1100,
      position: 'relative',
      whiteSpace: 'nowrap',
      width: DRAWER_WIDTH,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      borderRight: "0"
    },
  
    [`& .${classes.drawerPaperClose}`]: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    },
  
    [`& .${classes.appBarSpacer}`]: {
    },
  
    [`& .${classes.content}`]: {
      flexGrow: 1,
      minHeight: '100vh',
      overflow: 'auto',
      
    backgroundColor: theme.palette.mode === 'light' ? '#F5F5F5' : '#1b252d' 
    },
  
    [`& .${classes.container}`]: {
      paddingTop: 18,
      paddingInline: "10px"
    },
  
    [`& .${classes.paper}`]: {
      padding: theme.spacing(2),
      display: 'flex',
      overflow: 'auto',
      flexDirection: 'column',
    },
  
    [`& .${classes.fixedHeight}`]: {
      height: 240,
    }
  }))
  

const StatusRedis = (props) => {
    const service = new AuthService()
    const [status, setStatus] = useState()

    useEffect(() => {
        service.get('/redis/status', []).then(result => setStatus(result)).catch(err => console.log(err))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            Status do cache: {status}
        </Typography>
    );
}

 
 
export function Layout() {
    
    const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const [open, setOpen] = useState(!isMobile)
    const [open, setOpen] = useState(localStorage.getItem('_side_panel_') ? localStorage.getItem('_side_panel_') === "true" : true)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const service = new AuthService()

        service.get('/verify-session').then(async () => {
            const token = AuthService.getToken()
            await service.load(token)
        }).catch(err => {
            console.log(err)
            if (err.response.status === 401) {
                handleLogout()
            }
        }).finally(() => setLoading(false))
    }, [])

  

    const toggleThemeMode = () => {
        dispatch({ type: 'TOGGLE_THEME_MODE' })
    }

    function handleLogout() {
        dispatch({ type: 'LOGOUT_USER' })

        localStorage.removeItem('id_token')
        localStorage.removeItem('_profile')

        navigate('/login')
    }

    function handleRoute(route) {
        navigate(route)
    }
    const handleDrawerOpen = () => {
        setOpen(true)
        localStorage.setItem('_side_panel_', true)
      }

    const handleDrawerClose = () => {
        setOpen(false)
        localStorage.setItem('_side_panel_', false)
    }

    return (
        <Root className={classes.root}>
        
            <CssBaseline />

            <AppBar 
                position="absolute" open={open} elevation={0}
                className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar 
                    className={clsx(classes.toolbar, open && classes.toolbarOpen)}>
                    
                    {open && (
                        <IconButton onClick={handleDrawerClose} size="large"  color="inherit">
                        <ChevronLeftIcon />
                        </IconButton>
                    )}

                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="Abrir menu"
                        onClick={handleDrawerOpen}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        size="large">
                        <MenuIcon />
                    </IconButton>

 

                    <Typography
                        noWrap
                        variant="h4"
                        component="h1"
                        color="inherit"
                        sx={{ flexGrow: 1 }}
                        align='left'>
                        SISCHEF ANALYTICS
                    </Typography>

                    <Hidden mdDown>
                        <Tooltip disableInteractive title="Programa de benefícios">
                            <IconButton href="https://sischef.com/programa-de-beneficios/" target="_blank" size="large" color="inherit">
                                <EmojiEventsIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip disableInteractive title="Conheça nosso EAD">
                            <IconButton href="https://www.sympla.com.br/play/ensina-sischef/1365178" target="_blank" size="large" color="inherit">
                                <SchoolIcon />
                            </IconButton>
                        </Tooltip>
                    </Hidden>

                    <Tooltip disableInteractive title="Converse com um atendente">
                        <IconButton href={`${process.env.REACT_APP_PDV_URL}/helpdesk`} target="_blank" size="large" color="inherit">
                            <Badge color="warning" overlap="circular" badgeContent=" " variant="dot">
                                <HeadsetMicIcon />
                            </Badge>
                        </IconButton>
                    </Tooltip>

                    <IconButton color="inherit" onClick={() => toggleThemeMode()} size="large">
                        <Brightness4 />
                    </IconButton>

                </Toolbar>
            </AppBar>

            <Drawer 
                variant={isMobile ? "temporary" : "permanent"} open={open} elevation={0}
                classes={{
                    paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                  }}
                  onClose={handleDrawerClose}>

                {!isMobile ? (
                    <Toolbar disableGutters>
                        {open && <MenuSistema />}
                    </Toolbar>
                ) : (open && <MenuSistema />)}

                {open && <Profile />}

                <List component="nav">
                    <ListItemButton onClick={() => handleRoute('/')}>
                        <ListItemIcon><AccessTimeIcon /></ListItemIcon>
                        <ListItemText primary="Em Tempo real" />
                    </ListItemButton>

                    <ListItemButton onClick={() => handleRoute('/sales')}>
                        <ListItemIcon><PointOfSaleIcon /></ListItemIcon>
                        <ListItemText primary="Vendas" />
                    </ListItemButton>

                    <ListItemButton onClick={() => handleRoute('/financial')}>
                        <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
                        <ListItemText primary="Financeiro" />
                    </ListItemButton>

                    <ListItemButton onClick={() => handleRoute('/product')}>
                        <ListItemIcon><InventoryIcon /></ListItemIcon>
                        <ListItemText primary="Produto" />
                    </ListItemButton>

                    <ListItemButton disabled onClick={() => handleRoute('/clients')}>
                        <ListItemIcon><RecentActorsIcon /></ListItemIcon>
                        <ListItemText primary="Cliente" />
                    </ListItemButton>

                    <ListItemButton onClick={() => handleRoute('/checking')}>
                        <ListItemIcon><KeyboardCommandKeyIcon /></ListItemIcon>
                        <ListItemText primary="Controle" />
                    </ListItemButton>

                    <ListItemButton onClick={() => handleRoute('/franchise')}>
                        <ListItemIcon><StoreIcon /></ListItemIcon>
                        <ListItemText primary="Franquia" />
                    </ListItemButton>

                    <ListItemButton onClick={handleLogout}>
                        <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                        <ListItemText primary="Sair" />
                    </ListItemButton>

                    <Divider />

                    {open && !loading && <StatusRedis sx={{ pt: 2 }} />}
                </List>
            </Drawer>

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                <Container maxWidth={false} className={classes.container}>
                    {loading ? (
                            <Box pt={8}>
                                <Typography>😎 Carregando o Analytics...</Typography>
                            </Box>
                        ) : (
                            <Outlet />
                        )}
                </Container>
            </main>
 
        </Root>
    )
}