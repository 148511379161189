export const initialKpiOrderIntegration = () => ({
    listAllOrderIntegration: [],
    currentMonthOrderIntegration: [],
    lastMonthOrderIntegration: [],
});

function groupByMonth(data) {
    const groupedData = {};

    data.forEach((item) => {
        const month = item.mes;
        groupedData[month] = groupedData[month] || { month, records: [] };
        groupedData[month].records.push(item);
    });

    return Object.values(groupedData);
}

export const kpiOrderIntegration = (currentState, action) => {
    const currentDate = new Date(action.selectedDate);

    const kpiOrderIntegration = {
        listAllOrderIntegration: action.kpiOrderIntegration,
        currentMonthOrderIntegration: [],
        lastMonthOrderIntegration: [],
        selectedDate: new Date(action.selectedDate),
        currentDate: currentDate.getMonth() + 1,
        currentMonth: currentDate.getMonth() + 1,
        currentYear: currentDate.getFullYear(),
        currentDay: currentDate.getDate(),
        date: new Date(),
        isCurrentMonth: currentDate.getMonth() + 1 === currentDate.getMonth() + 1,
    };

    const groupedData = groupByMonth(kpiOrderIntegration.listAllOrderIntegration || []);

    kpiOrderIntegration.currentMonthOrderIntegration = [...(groupedData.find(e => e.month === kpiOrderIntegration.currentMonth)?.records || [])];
    kpiOrderIntegration.lastMonthOrderIntegration = [...(groupedData.find(e => e.month !== kpiOrderIntegration.currentMonth)?.records || [])];

    return {
        ...currentState,
        kpiOrderIntegration,
    };
};
