import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const RedirectToUnifiedLogin = ({ children }) => {

  const DIABLE_REDIRECT = true;
  const [message, setMessage] = useState('Carregando...');
  const [redirecting, setRedirecting] = useState(false);

  useEffect(() => {

    if (DIABLE_REDIRECT){
      setMessage("")
    } else{ 
      // Se o hostname não for 'localhost', redireciona para a URL definida na variável de ambiente
      if (window.location.hostname !== 'localhost') {
        const loginUrl = process.env.REACT_APP_LOGIN_PAGE;
        if (loginUrl) {
          setRedirecting(true);
          setMessage('Redirecionando...');
          window.location.href = loginUrl;
        } else {
          setMessage('URL de login não configurada.');
        }
      } else {
        // Em desenvolvimento (localhost) não redireciona
        setMessage('Você está no ambiente de desenvolvimento. Não vai utilizar o login unificado');
      }
    }
   
  }, []);

  return (
    <Box p={3} >
      {redirecting && ( <CircularProgress /> )}
      <Typography variant="h6" mt={2}>
        {message}
      </Typography>

      {!redirecting && children }
    </Box>
  );
};

export default RedirectToUnifiedLogin;
