import { roundTo } from "round-to";
import { filterRecordsByDate, months } from "../../../utils/helper";

export const CumulativeSalesPerStore = (sales) => {
    let result = {
        caption: 'Vendas por unidade',
        data: [],
        summary: [],
        index: "store",
        categories: [],
        colors: ["indigo", "cyan"],
        yAxisWidth: 90,
    };

    // Verifica se há vendas no mês atual
    const existsCurrentMonthSales = sales?.currentMonthSales?.length;
    if (!existsCurrentMonthSales) return result; // Retorna um resultado vazio se não houver vendas

    // Identifica o mês atual e o mês anterior
    const actualMonth = sales.currentMonth - 1;
    const lastMonth = actualMonth === 0 ? 11 : actualMonth - 1;
    result.categories.push(months[lastMonth], months[actualMonth]);

    // Função para extrair as lojas a partir dos dados
    const extractStore = (data) => {
        const stores = data.map(item => ({ store: item.idUnidade, description: item.unidadeName }));
        return [...new Set(stores.map(store => JSON.stringify(store)))].map(store => JSON.parse(store)); // Remove duplicados
    };

    // Função para agrupar os dados por data e loja
    const groupByDateAndStore = (data, totalDaysInMonth = 31) => {
        const groupedData = data.reduce((acc, { dia, idUnidade, valorTotal }) => {
            if (!acc[dia]) acc[dia] = { dia }; // Inicializa a chave do dia
            acc[dia][idUnidade] = (acc[dia][idUnidade] || 0) + Number(valorTotal); // Acumula as vendas por loja e dia
            return acc;
        }, {});

        // Preenche dias faltantes com valor 0 para todas as lojas
        for (let dia = 1; dia <= totalDaysInMonth; dia++) {
            if (!groupedData[dia]) groupedData[dia] = { dia };
            const storeList = extractStore(sales.currentMonthSales);
            storeList.forEach(store => {
                if (!groupedData[dia][store.store]) {
                    groupedData[dia][store.store] = 0; // Garante que todas as lojas têm valores, mesmo que seja 0
                }
            });
        }

        return Object.values(groupedData);
    };

    const groupByDateAndStoreCurrentMonth = groupByDateAndStore(sales.currentMonthSales);
    let groupByDateAndStoreLastMonth;

    // Filtra os dados do mês anterior com base na comparação de data
    if ((!sales.compareWith || sales.compareWith === 'CURRENT_DATE') && sales.isCurrentMonth) {
        groupByDateAndStoreLastMonth = groupByDateAndStore(filterRecordsByDate(sales.lastMonthSales, sales.currentDay));
    } else {
        groupByDateAndStoreLastMonth = groupByDateAndStore(filterRecordsByDate(sales.lastMonthSales));
    }

    const storeList = extractStore(sales.currentMonthSales);

    // Função para buscar uma loja específica pelo ID
    const filterByStore = (data, storeId) => data?.find(item => item?.store === storeId);

    // Função para consolidar as vendas por loja
    const consolidateByStore = (data) => {
        const consolidated = data.reduce((acc, { dia, ...stores }) => {
            Object.keys(stores).forEach((storeId) => {
                const valor = stores[storeId];
                if (!acc[storeId]) acc[storeId] = { store: storeId, totalValue: 0 };
                acc[storeId].totalValue += parseFloat(valor); // Acumula o valor total das vendas
            });
            return acc;
        }, {});
        return Object.values(consolidated); // Retorna as lojas consolidadas
    };

    // Consolida os dados do mês atual e do mês anterior
    const consolidatedByStoreCurrentMonth = consolidateByStore(groupByDateAndStoreCurrentMonth);
    const consolidatedByStoreLastMonth = consolidateByStore(groupByDateAndStoreLastMonth);


    const sortedConsolidatedCurrentMonth = consolidatedByStoreCurrentMonth.sort((a, b) => b.totalValue - a.totalValue); // Ordenando em ordem decrescente

    const consolidatedWithChange = sortedConsolidatedCurrentMonth.map(item => {
        const totalLast = filterByStore(consolidatedByStoreLastMonth, item.store);
        const change = totalLast && totalLast.totalValue !== 0
            ? ((item.totalValue - totalLast.totalValue) / totalLast.totalValue) * 100
            : 0; // Calcula a variação percentual
        const changeType = totalLast && totalLast.totalValue < item.totalValue ? 'positive' : 'negative'; // Define o tipo de mudança (positivo ou negativo)
    
        return {
            ticker: item.store,
            description: filterByStore(storeList, item.store)?.description || '', // Descrição da loja
            value: item.totalValue,
            change: roundTo(change, 2) + '%', // Arredonda a mudança para 2 casas decimais
            changeType: changeType, // Tipo de mudança
        };
    });
    

    result.data = groupByDateAndStoreCurrentMonth; // Dados agrupados para o mês atual
    result.summary = consolidatedWithChange; // Resumo com as mudanças de vendas

    return result; // Retorna o resultado final
};
