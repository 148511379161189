import { roundTo, roundToUp } from "round-to";
import { formatNumber, formatNumberToMM } from "../../../utils/number";
import { isMobile } from "react-device-detect";
import { months } from "../../../utils/helper";

function getDiasMes(month, year) {
    month--;

    var date = new Date(year, month, 1);
    var days = [];

    while (date.getMonth() === month) {
        days.push(date.getDate());
        date.setDate(date.getDate() + 1);
    }

    return days;
}

function sumQuantity(data) {

    const total = data?.reduce((sum, checking) => {
        const quantity = parseFloat(checking.quantidade);
        return sum + quantity;
    }, 0);

    return total || 0;
}
function sumValue(data) {

    const total = data?.reduce((sum, checking) => {
        const quantity = parseFloat(checking.valorTotal);
        return sum + quantity;
    }, 0);

    return total || 0;
}

function sumValueToDate(data, targetDate) {
    let sum = 0;

    data?.forEach((checking) => {
        if (checking.dia <= targetDate) {
            sum += parseFloat(checking.valorTotal);
        }
    });

    return sum;
}

function groupByProduct(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const productName = item.produtoNome;
        if (!groupedData[productName]) {
            groupedData[productName] = {
                productName: productName,
                records: [],
            };
        }
        groupedData[productName].records.push(item);
    });

    return Object.values(groupedData);
}

function sumQuantityToDate(data, targetDate) {
    let sum = 0;

    data?.forEach((checking) => {
        if (checking.dia <= targetDate) {
            sum += parseFloat(checking.quantidade);
        }
    });

    return sum;
}

function groupByStore(data) {
    const groupedData = {};

    data?.forEach((item) => {
        // console.log('item', item)
        const store = item.unidadeName;
        if (!groupedData[store]) {
            groupedData[store] = {
                store: store,
                records: [],
            };
        }
        groupedData[store].records.push(item);
    });

    return Object.values(groupedData);
}

export const CheckingCountKPI = (checking, isMobile) => {
    const {
        currentMonthChecking,
        lastMonthChecking,
        currentMonth,
        currentDay,
        isCurrentMonth,
    } = checking || {};

    const existsCurrentMonth = currentMonthChecking && currentMonthChecking.length;
    const existsLastMonth = lastMonthChecking && lastMonthChecking.length;

    if (!existsCurrentMonth) {
        return {
            caption: 'Qtde. itens cancelados',
            descriptionComparative: '',
            metric: 0,
            badgeDelta: 0,
            progress: 0,
            target: 0,
            deltaType: 'moderateIncrease',
        };
    }

    const actualMonth = currentMonth - 1;
    const lastMonth = (actualMonth - 1 + 12) % 12;

    const result = {
        caption: 'Qtde. itens cancelados',
        descriptionComparative: `${months[lastMonth]}`,
        metric: isMobile ? formatNumberToMM(sumQuantity(currentMonthChecking)) : formatNumber(sumQuantity(currentMonthChecking), 'decimal'),
    };

    if (existsLastMonth) {
        const sumCurrent = sumQuantity(currentMonthChecking);
        const sumLast = sumQuantity(lastMonthChecking);
        result.target = isMobile ? formatNumberToMM(sumLast) : formatNumber(sumLast, 'decimal');
        result.progress = roundToUp((sumCurrent / sumLast) * 100, 2);

        if (existsCurrentMonth && isCurrentMonth) {
            const sumQuantityByToday = sumQuantityToDate(lastMonthChecking, currentDay);
            result.badgeDelta = roundToUp(((sumCurrent - sumQuantityByToday) / sumQuantityByToday) * 100, 2);
        } else {
            result.badgeDelta = roundToUp(((sumCurrent - sumLast) / sumLast) * 100, 2);
        }
    }

    return result;
};

export const CheckingValueKPI = (checking, isMobile) => {
    const {
        currentMonthChecking,
        lastMonthChecking,
        currentMonth,
        currentDay,
        isCurrentMonth,
    } = checking || {};

    const existsCurrentMonth = currentMonthChecking && currentMonthChecking.length;
    const existsLastMonth = lastMonthChecking && lastMonthChecking.length;

    if (!existsCurrentMonth) {
        return {
            caption: 'R$ Cancelamentos',
            descriptionComparative: '',
            metric: 0,
            badgeDelta: 0,
            progress: 0,
            target: 0,
            deltaType: 'moderateIncrease',
        };
    }

    const actualMonth = currentMonth - 1;
    const lastMonth = (actualMonth - 1 + 12) % 12;

    const result = {
        caption: 'R$ Cancelamentos',
        descriptionComparative: `${months[lastMonth]}`,
        metric: isMobile ? formatNumberToMM(sumValue(currentMonthChecking)) : formatNumber(sumValue(currentMonthChecking), 'decimal'),
    };

    if (existsLastMonth) {
        const sumCurrent = sumValue(currentMonthChecking);
        const sumLast = sumValue(lastMonthChecking);
        result.target = isMobile ? formatNumberToMM(sumLast) : formatNumber(sumLast, 'decimal');
        result.progress = roundToUp((sumCurrent / sumLast) * 100, 2);

        if (existsCurrentMonth && isCurrentMonth) {
            const sumByToday = sumValueToDate(lastMonthChecking, currentDay);
            result.badgeDelta = roundToUp(((sumCurrent - sumByToday) / sumByToday) * 100, 2);
        } else {
            result.badgeDelta = roundToUp(((sumCurrent - sumLast) / sumLast) * 100, 2);
        }
    }

    return result;
};

export const CumulativeCheckingByUserKPI = (checking) => {
    const existsCurrentMonth = checking && checking.currentMonthChecking && checking.currentMonthChecking.length;

    if (!existsCurrentMonth) {
        return {
            height: 'h-[800px]',
            caption: 'Cancelamentos ( Unidade X Usuário )',
            data: [],
            yAxisWidth: 180,
            index: "description",
            categories: [],
            layout: 'vertical',
        };
    }

    const actualMonth = checking.currentMonth - 1;
    const lastMonth = (actualMonth - 1 + 12) % 12; // Use modulo to handle negative values

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    const result = {
        height: 'h-[800px]',
        caption: 'Cancelamentos ( Unidade X Usuário )',
        data: [],
        yAxisWidth: 180,
        index: "description",
        categories: [lastMonthDescription, currentMonthDescription],
        layout: 'vertical',
    };

    const groupedDataCurrentMonth = groupByStore(checking.currentMonthChecking);
    const groupedDataLastMonth = groupByStore(checking.lastMonthChecking);

    function filterByStore(data, store) {
        return data?.find(item => item?.store?.toUpperCase() === store?.toUpperCase());
    }

    function extractUser(data) {
        const userList = data.flatMap(item => item.records.map(rec => rec.nomeUsuarioExclusao?.toUpperCase()));
        return [...new Set(userList)];
    }

    function extractStore(data) {
        const stores = data.flatMap(item => item.records.map(rec => rec.unidadeName?.toUpperCase()));
        return [...new Set(stores)];
    }

    const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];
    const userList = extractUser(groupedData);
    const storeList = extractStore(groupedData);

    function calculateTotals(groupedData, store) {
        const totalsByUser = {};

        groupedData
            .filter(e => e.store === store)
            .forEach(item => {
                item.records.forEach(record => {
                    const userName = record.nomeUsuarioExclusao?.toUpperCase();

                    if (userList.includes(userName)) {
                        totalsByUser[userName] = (totalsByUser[userName] || 0) + parseFloat(record.valorTotal);
                    }
                });
            });

        const resultList = Object.entries(totalsByUser).map(([userName, total]) => ({ name: userName, value: total }));
        const sortedResultList = resultList.sort((a, b) => b.value - a.value);
        return sortedResultList;

    }

    const chartData = storeList.map(store => {
        const metricLastMonth = sumValue(filterByStore(groupedDataLastMonth, store)?.records);
        const metricCurrentMonth = sumValue(filterByStore(groupedDataCurrentMonth, store)?.records);

        const targetLastMonth = roundTo((metricLastMonth / sumValue(checking.lastMonthChecking)) * 100, 2);
        const targetCurrentMonth = roundTo((metricCurrentMonth / sumValue(checking.currentMonthChecking)) * 100, 2);

        const resultListLast = calculateTotals(groupedDataLastMonth, store);
        const resultListCurrent = calculateTotals(groupedDataCurrentMonth, store);

        return {
            description: store,
            [lastMonthDescription]: { metric: metricLastMonth, delta: targetLastMonth, cumulativeList: resultListLast },
            [currentMonthDescription]: { metric: metricCurrentMonth, delta: targetCurrentMonth, cumulativeList: resultListCurrent }
        };
    });

    result.data = chartData;

    return result;
};


export const CumulativeCheckingByProductKPI = (checking) => {
    const existsCurrentMonth = checking && checking.currentMonthChecking && checking.currentMonthChecking.length

    let result = {
        height: 'h-[800px]',
        caption: 'Cancelamentos ( Produtos X Unidade)',
        data: [],
        yAxisWidth: 180,
        index: "description",
        categories: [],
        layout: 'vertical',
    }

    if (!existsCurrentMonth) {
        return result
    }

    const actualMonth = checking.currentMonth - 1;
    let lastMonth = actualMonth - 1

    if (lastMonth < 0) {
        lastMonth = 11
    }

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    result.categories.push(lastMonthDescription, currentMonthDescription)

    const groupedDataCurrentMonth = groupByProduct(checking.currentMonthChecking)
    const groupedDataLastMonth = groupByProduct(checking.lastMonthChecking)


    function filterByProduct(data, product) {
        return data?.find(item => item?.productName?.toUpperCase() === product?.toUpperCase());
    }

    function extractProduct(data) {
        const products = data.map(item => item.productName?.toUpperCase());

        return products.filter((value, index) => products.indexOf(value?.toUpperCase()) === index);
    }

    function extractStore(data) {
        const stores = data.flatMap(item => item.records.map(rec => rec.unidadeName?.toUpperCase()));
        return [...new Set(stores)];
    }

    const groupedData = [...groupedDataCurrentMonth, ...groupedDataLastMonth];

    const productList = extractProduct(groupedData)

    const storeList = extractStore(groupedData);

    let chartData = [];

    const totalCurrentMonth = sumValue(checking.currentMonthChecking)
    const totalLastMonth = sumValue(checking.lastMonthChecking)

    function calculateTotals(groupedData, product) {


        const totalsByUnidade = {};

        groupedData.filter(e => e.productName === product).map(item => {
            item.records.map(record => {

                if (storeList.includes(record.unidadeName.toUpperCase())) {

                    if (totalsByUnidade[record.unidadeName] === undefined) {
                        totalsByUnidade[record.unidadeName] = 0;
                    }

                    totalsByUnidade[record.unidadeName] += parseFloat(record.valorTotal);
                }
            });
        });

        const resultList = Object.entries(totalsByUnidade).map(([unidadeName, total]) => ({ name: unidadeName, value: total }));

        const sortedResultList = resultList.sort((a, b) => b.value - a.value);

        return sortedResultList;
    }


    productList.forEach(product => {


        let metricLastMonth = sumValue(filterByProduct(groupedDataLastMonth, product)?.records)
        let quantityLastMonth = sumQuantity(filterByProduct(groupedDataLastMonth, product)?.records)
        let metricCurrentMonth = sumValue(filterByProduct(groupedDataCurrentMonth, product)?.records)
        let quantityCurrentMonth = sumQuantity(filterByProduct(groupedDataCurrentMonth, product)?.records)

        let targetLastMonth = roundTo((metricLastMonth / totalLastMonth) * 100, 2)
        let targetCurrentMonth = roundTo((metricCurrentMonth / totalCurrentMonth) * 100, 2)

        const resultListLast = calculateTotals(groupedDataLastMonth, product);
        const resultListCurrent = calculateTotals(groupedDataCurrentMonth, product);

        chartData.push({
            description: product,
            [lastMonthDescription]: { metric: metricLastMonth, delta: targetLastMonth, quantity: quantityLastMonth, cumulativeList: resultListLast },
            [currentMonthDescription]: { metric: metricCurrentMonth, delta: targetCurrentMonth, quantity: quantityCurrentMonth, cumulativeList: resultListCurrent }
        });
    })


    result.data = chartData

    return result
}