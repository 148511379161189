export const initialKpiChecking = () => ({
    listAllChecking: [],
    currentMonthChecking: [],
    lastMonthChecking: [],
});

function groupByMonth(data) {
    const groupedData = {};

    data.forEach((item) => {
        const month = item.mes;
        groupedData[month] = groupedData[month] || { month, records: [] };
        groupedData[month].records.push(item);
    });

    return Object.values(groupedData);
}

export const kpiChecking = (currentState, action) => {
    const currentDate = new Date(action.selectedDate);

    const kpiChecking = {
        listAllChecking: action.kpiChecking,
        currentMonthChecking: [],
        lastMonthChecking: [],
        selectedDate: new Date(action.selectedDate),
        currentDate: currentDate.getMonth() + 1,    
        currentMonth: currentDate.getMonth() + 1,
        currentYear: currentDate.getFullYear(),
        currentDay: currentDate.getDate(),
        date: new Date(),
        isCurrentMonth: currentDate.getMonth() + 1 === currentDate.getMonth() + 1,
    };

    const groupedData = groupByMonth(kpiChecking.listAllChecking||[]);

    const groupedPendindImportData = groupByMonth(kpiChecking.listAllCheckingPendingImport||[]);

    kpiChecking.currentMonthChecking = [...(groupedData.find(e => e.month === kpiChecking.currentMonth)?.records || [])];
    kpiChecking.lastMonthChecking = [...(groupedData.find(e => e.month !== kpiChecking.currentMonth)?.records || [])];
      
    return {
        ...currentState,
        kpiChecking,
    };
};
