import {
    Card,
    TabList,
    Tab,
    ProgressBar,
    Text,
    Flex,
    TabGroup,
    Title,
    Grid,
    BarList,
} from "@tremor/react";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import { formatNumber, formatNumberToMM } from "../../../utils/number";

export default function ListProgressBar(props) {
    const [selectedIndex, setSelectedIndex] = useState(1);

    const { caption, data, categories } = props;

    const dataFormatterCurrency = (number) => {
        return number.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    };

    return (
        <Grid numItemsSm={1} numItemsLg={1} className="gap-12">
            <Card>
                <Flex alignItems="start">
                    <Title>{caption}</Title>
                </Flex>
                <TabGroup index={selectedIndex} onIndexChange={setSelectedIndex} className="mt-3">
                    <TabList>
                        {categories.map((e, index) => (
                            <Tab key={index} index={index}>{e}</Tab>
                        ))}
                    </TabList>
                </TabGroup>

                {data
                    .sort((a, b) => b[categories[selectedIndex]].metric - a[categories[selectedIndex]].metric)
                    .map((item, index) => (
                        <div key={index} className="mt-4 border-b pb-4">
                            <Flex flexDirection='col'>
                                <Flex className="justify-between">
                                    <Text>{isMobile ? item.description.substring(0, 15) : item.description}</Text>
                                    <Text>
                                        {` ${item[categories[selectedIndex]].quantity !== undefined ? 'Qtde: ' + formatNumber(item[categories[selectedIndex]].quantity, { style: 'decimal' }) + ' | ' : ''} 
                                            Total: ${isMobile ? formatNumberToMM(item[categories[selectedIndex]].metric) : formatNumber(item[categories[selectedIndex]].metric, { style: 'currency' })}`}
                                    </Text>
                                </Flex>

                                <Flex className="space-x-3">
                                    <ProgressBar className="mt-1" value={item[categories[selectedIndex]].delta} />
                                    <Title>{item[categories[selectedIndex]].delta}%</Title>
                                </Flex>

                                {/* Exibição dos detalhes diretamente, sem Accordion */}
                                <div className="mt-2">
                                    <BarList
                                        key={index}
                                        data={item[categories[selectedIndex]].cumulativeList}
                                        valueFormatter={dataFormatterCurrency}
                                    />
                                </div>
                            </Flex>
                        </div>
                    ))}
            </Card>
        </Grid>
    );
}
