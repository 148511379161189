import { encode } from "base-64";

export default class AuthService {
  // Inicializando variáveis ​​importantes
  constructor(domain) {
    this.domain = domain || process.env.REACT_APP_API_URL; // Dominio do servidor da API
    //this.domain = domain || 'http://localhost:3007' // Dominio do servidor da API
    this.fetch = this.fetch.bind(this); // React binding stuff
    this.login = this.login.bind(this);
    this.getProfile = this.getProfile.bind(this);

    this.download = this.download.bind(this);
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
    this.atualizarEmpresaProfile = this.atualizarEmpresaProfile.bind(this);

    // console.log('aqui', domain, process.env.REACT_APP_API_URL)
  }

  login(username, password) {
    //primeira coisa, remove token caso exista
    this.logout();

    // console.log('login dominio', this.domain)
    // Obter um token do servidor da API usando a API de busca
    return fetch(`${this.domain}/session`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Basic " + encode(username + ":" + password),
      },
      method: "GET",
      mode: "cors",
    })
      .then(this._checkStatus)
      .then((response) => {
        return response.json().then((respJson) => {
          let token = respJson.token;
          if (token) {
            this.setToken(token);
            this.setProfile(respJson);
          } else {
            // throw "erro ao realizar login - token inválido"
          }
        });
      });
  }

  load(token) {
    //primeira coisa, remove token caso exista
    this.logout();

    // Obter um token do servidor da API usando a API de busca
    return fetch(`${this.domain}/reload`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then(this._checkStatus)
      .then((response) => {
        return response.json().then((respJson) => {
          let token = respJson.token;
          if (token) {
            this.setToken(token);
            this.setProfile(respJson);
          } else {
            // throw "erro ao realizar login - token inválido"
          }
        });
      });
  }

  refreshProfile() {
    const token = AuthService.getToken(); // Obtendo token do localstorage

    if (!token) {
      return;
    }

    // Obter um token do servidor da API usando a API de busca
    return fetch(`${this.domain}/api-v2/usuario/logged`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then(this._checkStatus)
      .then((response) => {
        this.setToken(token);

        return response.json().then((json) => {
          this.setProfile(json);
        });
      });
  }

  verificaBloqueio(dataBloqueio, usuario) {
    if (dataBloqueio) {
      let hoje = new Date();
      dataBloqueio = new Date(dataBloqueio);
      // console.log(usuario);

      if (!usuario.admin) {
        if (hoje.getTime() > dataBloqueio.getTime()) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }
  }

  isAdmin() {
    const usuario = this.getProfile();
    if (usuario.admin) {
      return true;
    } else {
      return false;
    }
  }

  static loggedIn() {
    // Verifica se há um token salvo e ainda é válido
    const token = AuthService.getToken(); // Obtendo token do localstorage
    return !!token && !AuthService.isTokenExpired(token);
  }

  static isTokenExpired(token) {
    try {
      // const decoded = decode(token);
      // if (decoded.exp < Date.now() / 1000) { //Verificando se o token expirou.
      //     return true;
      // }
      // else
      //     return false;
    } catch (err) {
      return false;
    }
  }

  setToken(idToken) {
    // Salva o token do usuário em localStorage
    localStorage.setItem("id_token", idToken);
  }

  static getToken() {
    // Recupera o token do usuário do localStorage
    return localStorage.getItem("id_token");
  }

  logout() {
    // Limpar token do usuário e dados de perfil do localStorage
    localStorage.removeItem("id_token");
  }

  getProfile() {
    // Usando o pacote jwt-decode npm para decodificar o token
    let profileString = localStorage.getItem("_profile");
    return JSON.parse(profileString);
    //return decode(this.getToken());
  }

  static profile() {
    let profileString = localStorage.getItem("_profile");
    return JSON.parse(profileString);
  }

  setProfile(profile) {
    localStorage.setItem("_profile", JSON.stringify(profile));
  }

  atualizarEmpresaProfile(empresa) {
    let profile = this.getProfile();
    profile.empresa = empresa;
    localStorage.setItem("_profile", JSON.stringify(profile));
  }

  fetch(url, options) {
    const token = AuthService.getToken();

    if (!options) {
      options = {};
    }
    options.headers = {
      //"Authorization": "Bearer " + (token != null ? token : ''),
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    //SO ADICIONA O HEADER SE HOUVER TOKEN
    if (token) {
      options.headers["Authorization"] = "Bearer " + token;
    }

    return fetch(url, options)
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  download(url, options) {
    const token = this.getToken();

    if (!options) {
      options = {};
    }
    options.headers = {
      Authorization: "Bearer " + (token != null ? token : ""),
      "Content-Type": "application/octet-stream",
    };
    return fetch(this.domain + url, options)
      .then(this._checkStatus)
      .then((response) => response.blob());
  }

  post(url, obj) {
    return fetch(this.domain + url, {
      method: "POST",
      body: JSON.stringify(obj),
      headers: {
        Authorization: "Bearer " + AuthService.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  get(url, params) {
    const headers = {
      Authorization: "Bearer " + AuthService.getToken(),
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };

    // Verifica se há parâmetros de consulta
    if (params) {
      const queryParams = new URLSearchParams(params);
      url = url + "?" + queryParams.toString();
    }

    const options = {
      method: "GET",
      headers: headers,
    };

    try {
      return fetch(this.domain + url, options)
        .then(this._checkStatus)
        .then((response) => response.json());
    } catch (err) {
      console.log('get => ', err.message)
    }
  }

  request(url, options) {
    options = {
      ...options,
      headers: {
        Authorization: "Bearer " + AuthService.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    };
    return fetch(this.domain + url, options).then(this._checkStatus);
  }

  put(url, obj) {
    return fetch(this.domain + url, {
      method: "PUT",
      body: JSON.stringify(obj),
      headers: {
        Authorization: "Bearer " + AuthService.getToken(),
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    })
      .then(this._checkStatus)
      .then((response) => response.json());
  }

  delete(url, obj) {
    return fetch(this.domain + url, {
      method: "DELETE",
      body: obj ? JSON.stringify(obj) : null,
      headers: {
        Authorization: "Bearer " + AuthService.getToken(),
        "Content-Type": "application/json; charset=utf-8",
      },
    }).then(this._checkStatus);
    //.then(response => response.json())
  }

  _checkStatus(response) {
    // console.log('resposta', response)

    // gera um erro caso o status da resposta não seja um sucesso
    if (response.status >= 200 && response.status < 300) {
      // O status de sucesso situa-se entre 200 e 300
      return response;
    } else {
      console.error('_checkStatus response', response)
      var error = { message: response.statusText };
      error.response = response;
      throw error;
    }
  }
}
