import { AreaChart, Card } from '@tremor/react';
import { currencyFormat } from '../../../utils/number';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}

const valueFormatter = (number) =>
    `${Intl.NumberFormat('us').format(number).toString()}`;
  

export default function SparkChart(props) {
    const { summary, data } = props;

    return (
        <div className="w-full">
            <dl className="w-full space-y-6 sm:mx-auto sm:max-w-full">
                {summary.map((stock) => (
                    <Card
                        key={stock.ticker}
                        className="flex flex-col space-y-4 p-4 w-full"
                    >
                        {/* Cabeçalho com ticker e descrição */}
                        <div className="flex items-center justify-between">
                            <div className="truncate">
                                <dt className="truncate text-tremor-default font-medium text-tremor-content-strong dark:text-dark-tremor-content-strong">
                                    {stock.ticker}
                                </dt>
                                <dl className="truncate text-tremor-label text-tremor-content dark:text-dark-tremor-content">
                                    {stock.description}
                                </dl>
                            </div>

                            {/* Exibição de valor e mudança */}
                            <div
                                className={classNames(
                                    stock.changeType === 'positive'
                                        ? 'text-emerald-700 dark:text-emerald-500'
                                        : 'text-red-700 dark:text-red-500',
                                    'flex items-center space-x-2',
                                )}
                            >
                                <dd className="text-tremor-default font-medium">
                                    {currencyFormat(stock.value)}
                                </dd>
                                <dd
                                    className={classNames(
                                        stock.changeType === 'positive'
                                            ? 'bg-emerald-100 dark:bg-emerald-400/10'
                                            : 'bg-red-100 dark:bg-red-400/10',
                                        'rounded px-1.5 py-1 text-tremor-label font-medium tabular-nums',
                                    )}
                                >
                                    {stock.change}
                                </dd>
                            </div>
                        </div>

                        {/* Gráfico ocupa a linha inteira abaixo */}
                        <AreaChart
                            data={data || []}
                            index="dia"
                            categories={[stock.ticker]}
                            colors={stock.changeType === 'positive' ? ['emerald'] : ['red']}
                            valueFormatter={valueFormatter}
                            showLegend={false}
                            showYAxis={false}
                            showGradient={true}
                            startEndOnly={false}
                            className="mt-6 h-32 w-full"
                            showGridLines={false}
                            barCategoryGap={true}
                                    
                        />


                        {/* <SparkAreaChart
                            data={data || []}
                            index="date"
                            categories={[stock.ticker]}
                            showGradient={true}
                            connectNulls={true}
                            colors={stock.changeType === 'positive' ? ['emerald'] : ['red']}
                            className="h-8 w-full" // Ocupa a largura total
                        /> */}
                    </Card>
                ))}
            </dl>
        </div>
    );
}
