import React from 'react';

import InsightsIcon from '@mui/icons-material/Insights';
import LaptopIcon from '@mui/icons-material/Laptop';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { Avatar, List, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList } from '@mui/material';

import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import AuthService from '../service';

export default function MenuSistema() {
    const jwtToken = AuthService.getToken();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
  
    const handleClickListItem = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    return (
         <React.Fragment>
             <List
                aria-label="Sistemas"
                disablePadding
                sx={{ width: '100%' }}
            >
                <ListItemButton
                    id="lock-button-sistemas"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClickListItem}
                >
                      <ListItemAvatar>
                        <Avatar alt="Sischef Analytics" src={process.env.PUBLIC_URL + "/logo-sischef.svg"}  />
                    </ListItemAvatar>
                <ListItemText
                    primary="Sischef "
                    primaryTypographyProps={{
                        style: { fontSize: "20px", fontWeight: "bold", letterSpacing: "0px" }
                    }}
                    secondary={'Analytics'}
                    secondaryTypographyProps={{
                        style: { marginTop: "-5px" }
                    }}
                />
                <MultipleStopIcon   />
                </ListItemButton>
            </List>


        <Menu
            id="lock-button-sistemas"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >
       <MenuList disablePadding>
        <MenuItem selected >
          <ListItemIcon>
            <InsightsIcon  />
          </ListItemIcon>
            <ListItemText
                    primary="Sischef Analytics"
                    
                    secondary={'O BI feito para seu food-service'}
                />
          
        </MenuItem>

        <MenuItem component="a"  href={process.env.REACT_APP_RETAGUARDA_URL + '/loginToken?token=' +  jwtToken} target='_blank'>
          <ListItemIcon>
            <LaptopIcon   />
          </ListItemIcon>
            <ListItemText
                    primary="Sischef retaguarda"
                    
                    secondary={'Sistema de gestão mais inteligente'}
                />
          
        </MenuItem>

        <MenuItem component="a"  href={process.env.REACT_APP_PDV_URL + '/load/' +  jwtToken} target='_blank'>
          <ListItemIcon>
            <StorefrontIcon   />
          </ListItemIcon>
            <ListItemText
                    primary="Sischef PDV"
                     
                    secondary={'O PDV mais moderno do Brasil'}
                />
          
        </MenuItem>
        </MenuList>
          
      </Menu>

      </React.Fragment>
    );
}