import React from 'react'

import { Box, Card, CardContent, CardHeader, Divider, Toolbar } from '@mui/material'
import { Outlet, useLocation } from 'react-router-dom'
import Filter from '../component/filter'

function getTitle(pathname) {
    switch (pathname) {
        case '/':
            return 'Dashboard'
        case '/sales':
            return 'Vendas'
        case '/financial':
            return 'Financeiro'
        case '/product':
            return 'Produto'
        case '/checking':
            return 'Controle'
        case '/unauthorized':
            return 'Acesso negado'
        case '/franchise':
            return 'Franquia'
        default:
            return 'Dashboard'
    }
}

export function AuthorizedDefaultLayout() {
    const location = useLocation()

    return (
        <>
            <Toolbar />

            <Box pb={2}>
                <Filter />
            </Box>

            <Card variant="outlined">
                <CardHeader
                    title={getTitle(location.pathname)}
                    titleTypographyProps={{
                        variant: "h4",
                        component: "h2",
                        style: { textAlign: "left" }
                    }} />

                <Divider />

                <CardContent>
                    <Outlet />
                </CardContent>
            </Card>
        </>
    )
}