import { Box, Grid, Paper, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthService from '../service';
import RedirectToUnifiedLogin from './RedirectToUnifiedLogin';

var md5 = require('md5');

const Login = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const [sending, setSending] = useState(false)
    const { enqueueSnackbar } = useSnackbar();
    const { search } = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(search);
        const loginToken = queryParams.get('token');

        if (loginToken) {
            let service = new AuthService()
            service.load(loginToken)
                .then(resp => {
                    setIsLoggedIn(true)

                    navigate('/')
                }).catch(err => {
                    enqueueSnackbar('Token inválido.', {
                        preventDuplicate: true,
                        variant: 'error',
                    })

                    // console.log('Usuário e/ou senha inválidos.', err)
                }).finally(() => {
                    setSending(false)
                })
        }
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();

        const data = new FormData(event.currentTarget);

        const usernameValue = data.get('email')
        const passwordValue = data.get('password')


        const passwordHash = md5(passwordValue);

        if (usernameValue && passwordHash) {
            setSending(true)
            let service = new AuthService();

            service.login(usernameValue, passwordHash).then(resp => {
                setIsLoggedIn(true);
            }).catch(err => {
                console.log(err)

                if (err?.response) {
                    err.response.text().then((text) => {
                        let message = 'Usuário e/ou senha inválidos'

                        if (text === 'Not Authorized') {
                            message = 'Você não tem permissão para acessar esse recurso'
                        }

                        enqueueSnackbar(message, {
                            preventDuplicate: true,
                            variant: 'error',
                        })
                    })
                } else {
                    enqueueSnackbar('Houve um erro ao efetuar o login', {
                        preventDuplicate: true,
                        variant: 'error',
                    })
                }
            }).finally(() => {
                setSending(false)
            })
        } else {
            enqueueSnackbar('Usuário e/ou senha não informado.', {
                preventDuplicate: true,
                variant: 'error',
            })
        }
    };

    // Se o usuário estiver logado, redirecione para a tela principal
    if (isLoggedIn) {
        navigate('/');
    }

    return (
        <div>

            <Grid container component="main" sx={{ height: '100vh' }}>

                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${process.env.PUBLIC_URL + '/static/images/layout/login-bg.jpg'})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />

                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square alignItems='center'>
                    <Box sx={{
                        my: 8,
                        mx: 10,
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <Avatar sx={{ width: 86, height: 86 }} src={process.env.PUBLIC_URL + "/logo-sischef.svg"} />

                            <Typography component="h1" variant="h3">
                                Sischef Analytics
                            </Typography>

                            <Typography component="h2" variant="h4">
                                Acesso
                            </Typography>

                        </Box>

                        <RedirectToUnifiedLogin >

                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    // value={'adm.hungaralanches'}
                                    id="username"
                                    label="Usuário"
                                    placeholder="Informe o usuário."
                                    name="email"
                                    autoComplete="username"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    // value={'160204'}
                                    label="Senha"
                                    type="password"
                                    id="password"
                                    placeholder="Informe a senha."
                                    autoComplete="password"
                                />

                                <Button
                                    type="submit"
                                    fullWidth
                                    disabled={sending}
                                    variant="contained"
                                    sx={{ mt: 3 }}
                                >
                                    Acessar
                                </Button>

                            </Box>

                        </RedirectToUnifiedLogin>
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};



export default Login;
