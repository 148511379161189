import React, { useState } from 'react'

import RedeemIcon from '@mui/icons-material/Redeem'
import { Box, Button, Container, CssBaseline, Paper, Typography } from '@mui/material'
import { styled } from '@mui/styles'
import { useSnackbar } from 'notistack'
import AuthService from '../../service'
import { useParams } from 'react-router-dom'

const PREFIX = 'Unauthorized'

const classes = {
    root: `${PREFIX}-root`,
    main: `${PREFIX}-main`,
    iconSize: `${PREFIX}-iconSize`
}

const Root = styled('div')(({ theme }) => ({
    [`&.${classes.root}`]: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },

    [`& .${classes.main}`]: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.iconSize}`]: {
        fontSize: '5em',
    },
}))

export default function Unauthorized() {
    const { role } = useParams()
    const { enqueueSnackbar } = useSnackbar()
    const [sending, setSending] = useState(false)
    const [solicitado, setSolicitado] = useState(false)
    const service = new AuthService()
    const profile = service.getProfile()
    const isGarcom = profile?.perfilUsuario?.tipo === 'garcom'

    function requestRole() {
        setSending(true)

        const endpoint = `/api-v2/sistema/solicitarFuncionalidade/${role}`

        service.fetch(process.env.REACT_APP_RETAGUARDA_URL + endpoint, {
            method: 'POST'
        }).then(() => {
            enqueueSnackbar('Solicitação realizada com sucesso. Logo entraremos em contato', { variant: 'success' })
            setSolicitado(true)
        }).catch(err => {
            enqueueSnackbar('Erro ao solicitar funcionalidade', { variant: 'error' })
            console.log('ERRO SOLICITAR FUNCIONALIDADE: ' + err)
        }).finally(() => setSending(false))
    }

    return (
        <Root className={classes.root}>

            <CssBaseline />

            <Container component="main" className={classes.main} maxWidth="sm">
                <Paper>
                    <Box p={3}>
                        <Typography variant="h2" component="h1" gutterBottom align="center">
                            Funcionalidade indisponível
                        </Typography>

                        <Box p={4} textAlign="center">
                            <RedeemIcon className={classes.iconSize} />
                        </Box>

                        <Typography
                            gutterBottom
                            variant="h5"
                            component="h2"
                            align="center">
                            Esta funcionalidade não está habilitada em sua conta. {isGarcom && 'Verifique com o seu gestor para ter acesso a está funcionalidade'}
                        </Typography>

                        <Box p={2} textAlign="center">
                            {solicitado ? (
                                <Typography variant="subtitle1" gutterBottom align="center">
                                    Agradecemos sua solicitação. Em breve entraremos em contato 🤝
                                </Typography>
                            ) : role && !isGarcom && (
                                <Button
                                    variant="outlined"
                                    disabled={sending}
                                    onClick={requestRole}>
                                    {sending ? "Solicitando..." : "Solicitar esta funcionalidade"}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Paper>
            </Container>

        </Root>
    )
}