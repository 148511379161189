"use client";

import {
    Grid,
    Tab,
    TabList,
    TabGroup,
    TabPanel,
    TabPanels,
} from "@tremor/react";
import { useSelector } from 'react-redux';
import { Skeleton } from "@mui/material";
import { CumulativeSalesPerStore } from "./helper";
import SparkChat from "../component/sparkCharts";
import { isLoading } from "../component/loading/store";
import ListFranchise from "./list";


export default function Franchise() {
    const kpiSales = useSelector((state) => state.kpiSales)
    const loading = useSelector((state) => isLoading(state, 'KPI_SALES'));

    const cumulativeSalesPerStoreKPI = CumulativeSalesPerStore(kpiSales)


    return (
        <div>
            <TabGroup className="mt-1">
                <TabList>
                    <Tab>Visão geral</Tab>
                    <Tab>Listagem</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        {!loading ?
                            <Grid className="gap-6 mt-6">
                                <SparkChat {...cumulativeSalesPerStoreKPI} />
                            </Grid>

                            :

                            <Grid className="gap-6 mt-6">
                                <Skeleton variant="rounded" height={100} />
                                <Skeleton variant="rounded" height={100} />
                                <Skeleton variant="rounded" height={100} />
                                <Skeleton variant="rounded" height={100} />
                            </Grid>
                        }
                    </TabPanel>
                    <TabPanel>
                        <ListFranchise data={cumulativeSalesPerStoreKPI.summary || []} />
                    </TabPanel>
                </TabPanels>
            </TabGroup>
        </div >
    );
}
