import React from "react"
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom"

import { ifAnyGranted } from "./auth"
import Checking from "./dashboard/checking"
import Financial from "./dashboard/financial"
import Franchise from "./dashboard/franchise"
import Product from "./dashboard/product"
import RealTime from "./dashboard/realTime"
import Sales from "./dashboard/sales"
import Unauthorized from "./dashboard/unauthorized"
import { Layout } from "./layout"
import { AuthorizedDefaultLayout } from "./layout/AuthorizedDefaultLayout"
import Login from "./login"
import AuthService from "./service"
import Target from "./target"

function isAuthenticated() {
    return AuthService.loggedIn()
}

function isAuthorized(role) {
    if (role) {
        return ifAnyGranted(role)
    }

    return true
}

function RequireAuth({ children }) {
    const location = useLocation()

    if (!isAuthenticated()) {
        return <Navigate to="/login" state={{ from: location }} replace />
    }

    return children
}

function RequireRole({ children, role }) {
    if (!isAuthorized(role)) {
        return <Navigate to={`/unauthorized/${role}`} />
    }

    return children
}

function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/login" element={<Login />} />
                <Route element={<RequireAuth><Layout /></RequireAuth>}>
                    <Route element={<AuthorizedDefaultLayout />}>
                        <Route path="/" element={<RealTime />} />
                        <Route path="/sales" element={<Sales />} />
                        <Route path="/financial" element={<Financial />} />
                        <Route path="/product" element={<Product />} />
                        <Route path="/checking" element={<Checking />} />
                        <Route path="/franchise" element={(
                            <RequireRole role="ROLE_SISCHEF_ANALYTICS_PRO">
                                <Franchise />
                            </RequireRole>
                        )} />
                        <Route path="/target" element={<Target />} />
                    </Route>
                    <Route path="/unauthorized/:role" element={<Unauthorized />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}

export default Router