import { roundToUp } from "round-to";
import numeral from 'numeral';


export const hashCode = (str) => {
    return str.split('').reduce((prevHash, currVal) =>
        (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
}

export const roundUp = (value, casas) => {
    let valueRoundUp = roundToUp(value, casas + 1)
    return parseFloat(valueRoundUp.toFixed(casas))
}


export const roundDown = (value, casas) => {
    let valueRoundUp = roundToUp(value, casas + 1)
    return parseFloat(valueRoundUp.toFixed(casas))
}


export const currencyFormat = (value, options = {}) => {
    const { simbol = 'R$' } = options;

    if (value === null || value === undefined || isNaN(value)) {
        return `${simbol} 0,00`;
    }

    // Arredonda o valor para cima com duas casas decimais
    const roundedValue = roundToUp(parseFloat(value), 2);

    // Formata o valor com separador de milhar e vírgula para casas decimais
    const formatted = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(roundedValue);

    return `${simbol} ${formatted}`;
};



export const formatNumber = (value, style = {}) => {
    // Exemplo de uso: formatNumber(123356.65, { style: 'currency', currency: 'BRL' }) -> "R$ 123.356,65"
    if (value === null || value === undefined) {
        return value;
    }

    return new Intl.NumberFormat('pt-BR', {
        currency: 'BRL',
        useGrouping: true,
        ...style
    }).format(value);
};



export function formatNumberToMM(number) {
    return numeral(number || 0).format('0.0a');
}


