import React from 'react';
import { useState } from "react";
import { DatePicker, ptBR } from '@mui/x-date-pickers';

function YearMonthPicker({ handleChangeTo }) {
    const [selectedDate, handleDateChange] = useState(new Date());

    return (
        <div className="picker">
            <DatePicker
                openTo="year"
                views={["year", "month"]}
                label="Mês e Ano"
                minDate={new Date("2015-01-01")}
                maxDate={new Date()}
                value={selectedDate}
                localeText={ptBR}
                slotProps={{ textField: {  fullWidth: true } }}
                onChange={val => {
                    handleDateChange(val);
                    handleChangeTo(val);
                }}
            />
        </div>
    );
}

export default YearMonthPicker;