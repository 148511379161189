import AuthService from "../service"

export function ifAnyGranted(role) {
    const profile = AuthService.profile()

    if (!profile.permissoes) {
        return false
    }

    return profile.permissoes.includes(role)
}