import { roundTo, roundToUp } from "round-to";
import { formatNumber, formatNumberToMM } from "../../../utils/number";
import { isMobile } from "react-device-detect";
import { months } from "../../../utils/helper";

function getDiasMes(month, year) {
    month--;

    var date = new Date(year, month, 1);
    var days = [];

    while (date.getMonth() === month) {
        days.push(date.getDate());
        date.setDate(date.getDate() + 1);
    }

    return days;
}

function sumQuantity(data) {

    const total = data?.reduce((sum, checking) => {
        const quantity = parseFloat(checking.quantidade);
        return sum + quantity;
    }, 0);

    return total || 0;
}
function sumValue(data) {

    const total = data?.reduce((sum, checking) => {
        const quantity = parseFloat(checking.valorTotal);
        return sum + quantity;
    }, 0);

    return total || 0;
}

function sumValueToDate(data, targetDate) {
    let sum = 0;

    data?.forEach((checking) => {
        if (checking.dia <= targetDate) {
            sum += parseFloat(checking.valorTotal);
        }
    });

    return sum;
}

function groupByProduct(data) {
    const groupedData = {};

    data?.forEach((item) => {
        const productName = item.produtoNome;
        if (!groupedData[productName]) {
            groupedData[productName] = {
                productName: productName,
                records: [],
            };
        }
        groupedData[productName].records.push(item);
    });

    return Object.values(groupedData);
}

function sumQuantityToDate(data, targetDate) {
    let sum = 0;

    data?.forEach((checking) => {
        if (checking.dia <= targetDate) {
            sum += parseFloat(checking.quantidade);
        }
    });

    return sum;
}

function groupByStore(data) {
    const groupedData = {};

    data?.forEach((item) => {
        // console.log('item', item)
        const store = item.unidadeName;
        if (!groupedData[store]) {
            groupedData[store] = {
                store: store,
                records: [],
            };
        }
        groupedData[store].records.push(item);
    });

    return Object.values(groupedData);
}

function filterByNotImported(data) {
    if (!Array.isArray(data) || data.length === 0) {
        return [];
    }
    
    return data.filter(item => 
        item?.dataImportacao == null && 
        item?.ativo === true && 
        item?.dataCancelamento == null
    );
}

export const OrderIntegrationPendingImportCountKPI = (kpiOrderIntegration) => {
    const {
        currentMonthOrderIntegration,
        lastMonthOrderIntegration,
        currentMonth
    } = kpiOrderIntegration || {};

    const existsCurrentMonth = currentMonthOrderIntegration && currentMonthOrderIntegration.length;
    const existsLastMonth = lastMonthOrderIntegration && lastMonthOrderIntegration.length;

    if (!existsCurrentMonth) {
        return {
            caption: 'Qtde. Pedidos não importados',
            descriptionComparative: '',
            metric: 0,
            badgeDelta: 0,
            progress: 0,
            target: 0,
            deltaType: 'moderateIncrease',
        };
    }
    
    const listCurrentMonthOrderNotImported = filterByNotImported(currentMonthOrderIntegration)
    const listLastMonthOrderNotImported = filterByNotImported(lastMonthOrderIntegration)
    
    const actualMonth = currentMonth - 1;
    const lastMonth = (actualMonth - 1 + 12) % 12;

    const result = {
        caption: 'Qtde. Pedidos não importados',
        descriptionComparative: `${months[lastMonth]}`,
        metric: isMobile ? formatNumberToMM(listCurrentMonthOrderNotImported.length) : formatNumber(listCurrentMonthOrderNotImported.length, { maximumFractionDigits: 0 }),
    };

    if (existsLastMonth) {
        const lengthCurrent = listCurrentMonthOrderNotImported.length;
        const lengthLast = listLastMonthOrderNotImported.length;
        result.target = isMobile ? formatNumberToMM(lengthLast) : formatNumber(lengthLast, { maximumFractionDigits: 0 });
        result.badgeDelta = roundToUp(((lengthCurrent - lengthLast) / lengthLast) * 100, 2);
    }

    return result;
};

export const OrderIntegrationPendingImportValueKPI = (kpiOrderIntegration, isMobile) => {
    const {
        currentMonthOrderIntegration,
        lastMonthOrderIntegration,
        currentMonth,
    } = kpiOrderIntegration || {};

    const existsCurrentMonth = currentMonthOrderIntegration && currentMonthOrderIntegration.length;
    const existsLastMonth = lastMonthOrderIntegration && lastMonthOrderIntegration.length;

    if (!existsCurrentMonth) {
        return {
            caption: 'R$ Pedidos não Importados',
            descriptionComparative: '',
            metric: 0,
            badgeDelta: 0,
            progress: 0,
            target: 0,
            deltaType: 'moderateIncrease',
        };
    }

    const actualMonth = currentMonth - 1;
    const lastMonth = (actualMonth - 1 + 12) % 12;

    const listCurrentMonthOrderNotImported = filterByNotImported(currentMonthOrderIntegration)
    const listLastMonthOrderNotImported = filterByNotImported(lastMonthOrderIntegration)
   
    const sumCurrent = sumValue(listCurrentMonthOrderNotImported);

    const result = {
        caption: 'R$ Pedidos não importados',
        descriptionComparative: `${months[lastMonth]}`,
        metric: isMobile ? formatNumberToMM(sumCurrent) : formatNumber(sumCurrent, 'decimal'),
    };

    if (existsLastMonth) {
        const sumLast = sumValue(listLastMonthOrderNotImported);
        result.target = isMobile ? formatNumberToMM(sumLast) : formatNumber(sumLast, 'decimal');
        result.badgeDelta = roundToUp(((sumCurrent - sumLast) / sumLast) * 100, 2);   
    }

    return result;
};

export const CumulativeOrderIntegrationPendingImportByUnidadeKPI = (kpiOrderIntegration) => {
    const {
        currentMonthOrderIntegration,
        lastMonthOrderIntegration,
        currentMonth,
        currentDay,
        isCurrentMonth,
    } = kpiOrderIntegration || {};

    const existsCurrentMonth = currentMonthOrderIntegration && currentMonthOrderIntegration.length;
    
    
    if (!existsCurrentMonth) {
        return {
            height: 'h-[800px]',
            caption: 'Pedidos não importados por Unidade',
            data: [],
            yAxisWidth: 180,
            index: "description",
            categories: [],
            layout: 'vertical',
        };
    }

    const actualMonth = currentMonth - 1;
    const lastMonth = (actualMonth - 1 + 12) % 12; // Use modulo to handle negative values

    const currentMonthDescription = `${months[actualMonth]}`;
    const lastMonthDescription = `${months[lastMonth]}`;

    const result = {
        height: 'h-[800px]',
        caption: 'Pedidos não importados por Unidade',
        data: [],
        yAxisWidth: 180,
        index: "description",
        categories: [lastMonthDescription, currentMonthDescription],
        layout: 'vertical',
    };


    const listCurrentMonthOrderNotImported = groupByStore(filterByNotImported(currentMonthOrderIntegration))
    const listAllCurrentMonthOrder = groupByStore(currentMonthOrderIntegration)

    const listLastMonthOrderNotImported =  groupByStore(filterByNotImported(lastMonthOrderIntegration))
    const listAllLastMonthOrder =  groupByStore(lastMonthOrderIntegration)
   
    function filterByStore(data, store) {
        return data?.find(item => item?.store?.toUpperCase() === store?.toUpperCase());
    }
    
    function extractStore(data) {
        const stores = data.flatMap(item => item.records.map(rec => rec.unidadeName?.toUpperCase()));
        return [...new Set(stores)];
    }

    const groupedData = [...listCurrentMonthOrderNotImported, ...listLastMonthOrderNotImported];

    const storeList = extractStore(groupedData);
    
    const chartData = storeList.map(store => {
        const metricCurrentMonth = sumValue(filterByStore(listCurrentMonthOrderNotImported, store)?.records || [])
        
        const metricAllCurrentMonth = sumValue(filterByStore(listAllCurrentMonthOrder, store)?.records || [])
        
        const targetCurrentMonth = roundTo((metricCurrentMonth / metricAllCurrentMonth)  * 100, 2);

        const metricLastMonth = sumValue(filterByStore(listLastMonthOrderNotImported, store)?.records || [])
        const metricAllLastMonth = sumValue(filterByStore(listAllLastMonthOrder, store)?.records || [])
        const targetLastMonth = roundTo((metricLastMonth / metricAllLastMonth) * 100, 2)
        

        return {
            description: store,
            [lastMonthDescription]: { metric: metricLastMonth, delta: targetLastMonth },
            [currentMonthDescription]: { metric: metricCurrentMonth, delta: targetCurrentMonth }
        };
    });

    result.data = chartData;

    return result;
};